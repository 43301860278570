import React from 'react'
import { useTranslation } from 'react-i18next'

function Footer() {
  const [t, i18n] = useTranslation();

  return (
     <div>
       <div className="container-fluid my-0">
        <div className="card  text-dark">
          <div className="card-body text-center bg-light">
            {t('footer')} <a href="https://wajen.menu" target="_blank" 
            className='text-decoration-none px-0 text-primary lead'>wajen.sa</a>
          </div>
        </div>
       </div>
    </div>
  )
}

export default Footer;
