import React, { useContext, useEffect } from "react";
import { ShopContext } from "../../context/shop-context";
import { useTranslation } from "react-i18next";
import { Instagram  } from 'react-content-loader';


const Buttons = () => {

    const {filterItem, menuItems, setItem, posts, arFilterItem, arMenuItems, dataIsLoading } = useContext(ShopContext);
   
const [t, i18n] = useTranslation();

    useEffect(()=>{
     
      setItem(posts)
    },[posts])

  return (
   
    <div>
      {dataIsLoading ? <Instagram /> :<>
      {i18n.language == 'ar' ? 
        <div className="coontainer-grid">
        <div className="btn-primary p-2  d-flex justify-content-center  sticky-top" >
        <button
            className="btn btn-secondary  p-2  mx-0 btn fw-bold text-white  py-2 px-5 rounded-5"
            onClick={() => setItem(posts)}>
             الــكـل
          </button>
          {arMenuItems.map((Val, id) => {
            return (
              <>  
          
              <button
                className="btn btn-secondary  mx-2 btn fw-bold text-whaite  py-0 px-5 rounded-5 "
                onClick={() => arFilterItem(Val)}
                key={id}>
                {Val}
              </button>
              </>
            );
          })}
          
     
        </div>
        </div>
      : 

   <div className="coontainer-grid">
      <div className="btn-primary  p-2  d-flex justify-content-center  sticky-top " >
      <button
          className="btn btn-secondary  p-2  mx-0 btn fw-bold text-white  py-2 px-5 rounded-5"
          onClick={() => setItem(posts)}>
          All
        </button>
        {menuItems.map((Val, id) => {
          return (
            <>  
        
            <button
              className="btn btn-secondary  mx-2 btn fw-bold text-white  py-0 px-5 rounded-5 "
              onClick={() => filterItem(Val)}
              key={id}
            >
              {Val}
            </button>
            </>
          );
        })}
        
       
      </div>
      </div>
}
</>
}
    </div>
  );
};

export default Buttons;
